import { Auth } from 'aws-amplify';
import Store from '@store';
import { getUserDisplayName, signOut, setLocalPath, localPath, clearStorage } from '@views/auth/commons/hostedUi';

const { commit } = Store;

const redirectToLogin = (to, next) => {
  setLocalPath(to.fullPath);
  next({
    path: '/auth/login',
    query: {
      redirect: to.fullPath,
    },
  });
};

const AuthFilter = async (to, from, next) => {
  const authRequired = to.matched.some(route => route.meta.auth);

  if (!authRequired) {
    next();
  } else {
    try {
      const session = await Auth.currentSession();

      if (session) {
        const sessionData = session.idToken;

        const displayName = getUserDisplayName(sessionData.payload);

        if (!sessionData.payload['cognito:groups'].length) {
          await signOut();
        }

        commit('user/setId', sessionData.payload.sub);

        commit('user/setAuthToken', sessionData.jwtToken);

        commit('user/setGroups', sessionData.payload['cognito:groups']);

        commit('user/setAttributes', {
          id: sessionData.payload.sub,
          email: sessionData.payload.email,
          phone: sessionData.payload.phone_number,
          name: displayName || null,
        });

        const localUrl = localPath();

        if (window.clarity) {
          // eslint-disable-next-line no-undef
          clarity('set', 'userId', sessionData.payload.email);
        }

        if (localUrl) {
          clearStorage();
          next(localUrl);
        } else {
          next();
        }
      } else {
        redirectToLogin(to, next);
      }
    } catch (e) {
      setLocalPath(to.fullPath);
      await signOut();
    }
  }
};

export default AuthFilter;
